<template>
  <div id="app">
    <v-app id="app">
      <router-view />
    </v-app>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "App",
  components: {},
  props: {
    // source: String,
  },
  data() {
    return {
      drawer: null,
      appName: "BCRM",
    };
  },
  created() {
    // axios.interceptors.response.use(undefined, (err) => {
    //   console.log(err.response.status)
    // })
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          this.$store.dispatch("user/logout").then(() => {
            if (this.$route.path !== "/login") {
              this.$router.push("/login");
            }
          });
        }
        if (error.response.status === 403) {
          if (this.$route.path !== "/forbidden") {
            this.$router.push("/forbidden");
          }
        }
        return Promise.reject(error);
      }
    );
  },
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  /*color: #2c3e50;*/
  /*margin-top: 60px;*/
}
</style>
