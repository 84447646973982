const token = state => state.token
const isLoggedIn = state => !!state.token
const isLoginError = state => (state.status === 'error')
const isLoginLoading = state => (state.status === 'loading')
const loginErrorMessage = state => state.loginErrorMessage
const authStatus = state => state.status
const username = state => state.username
const userFullName = state => state.userFullName
const userAvatar = state => state.userAvatar
const canCreateCustomer = state => state.permissions.includes('canCreateCustomer')
const canReadCustomer = state => state.permissions.includes('canReadCustomer')
const canUpdateCustomer = state => state.permissions.includes('canUpdateCustomer')
const canDeleteCustomer = state => state.permissions.includes('canDeleteCustomer')
const canCreateUser = state => state.permissions.includes('canCreateUser')
const canReadUser = state => state.permissions.includes('canReadUser')
const canUpdateUser = state => state.permissions.includes('canUpdateUser')
const canDeleteUser = state => state.permissions.includes('canDeleteUser')
const canReadDatabase = state => state.permissions.includes('canReadDatabase')
const canUpdateDatabase = state => state.permissions.includes('canUpdateDatabase')
const canDeleteDatabase = state => state.permissions.includes('canDeleteDatabase')

export {
    token,
    isLoggedIn,
    isLoginError,
    isLoginLoading,
    loginErrorMessage,
    authStatus,
    username,
    userFullName,
    userAvatar,
    canCreateCustomer,
    canReadCustomer,
    canUpdateCustomer,
    canDeleteCustomer,
    canCreateUser,
    canReadUser,
    canUpdateUser,
    canDeleteUser,
    canReadDatabase,
    canUpdateDatabase,
    canDeleteDatabase,
}