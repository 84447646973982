const state = {
    status: '',
    token: '',
    username: '',
    userFullName: '',
    userAvatar: '',
    loginErrorMessage: '',
    permissions: [],
}

export default state