const AUTH_REQUEST = (state) => {
    state.status = 'loading'
}

const AUTH_SUCCESS = (state, {
    token,
    username,
    fullname,
    avatar,
    permissions,
}) => {
    state.status = 'success'
    state.token = token
    state.username = username
    state.userFullName = fullname
    state.userAvatar = avatar
    state.permissions = permissions
    state.loginErrorMessage = ''
}

const AUTH_ERROR = (state, {
    loginErrorMessage
}) => {
    state.status = 'error'
    state.loginErrorMessage = loginErrorMessage
}

const CHANGE_AVATAR = (state, {
    avatar
}) => {
    state.userAvatar = avatar
}

const CHANGE_FULLNAME_REQUEST = (state) => {
    state.status = 'loading'
}

const CHANGE_FULLNAME_SUCCESS = (state, {
    fullname
}) => {
    state.userFullName = fullname
}

const LOGOUT = (state) => {
    state.status = ''
    state.token = ''
    state.username = ''
    state.userFullName = ''
    state.userAvatar = ''
    state.loginErrorMessage = ''
}

export {
    AUTH_REQUEST,
    AUTH_SUCCESS,
    AUTH_ERROR,
    CHANGE_AVATAR,
    CHANGE_FULLNAME_REQUEST,
    CHANGE_FULLNAME_SUCCESS,
    LOGOUT
}