import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'main',
      component: () => import('../components/Main.vue'),
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '/home',
          name: 'home',
          component: () => import('../components/Home.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/dashboard',
          name: 'dashboard',
          component: () => import('../components/Dashboard/index.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/customer',
          name: 'customer',
          component: () => import('../components/Customer/CustomerList.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/search-customer',
          name: 'SearchCustomerList',
          component: () => import('../components/Customer/SearchCustomerList.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/customer/:id/show',
          name: 'CustomerShow',
          component: () => import('../components/Customer/CustomerShow/index.vue'),
          meta: {
            requiresAuth: true,
          },
          props: true,
        },
        {
          path: '/customer/create',
          name: 'CustomerCreate',
          component: () => import('../components/Customer/CreateCustomer.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/customer/:id/edit',
          name: 'CustomerEdit',
          component: () => import('../components/Customer/EditCustomer.vue'),
          meta: {
            requiresAuth: true,
          },
          props: true,
        },
        {
          path: '/customer-crm-data',
          name: 'CustomerCRMDataList',
          component: () => import('../components/CustomerCRMData/CustomerCRMDataList.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/crm-data',
          name: 'CRMDataList',
          component: () => import('../components/CRMData/CRMDataList.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/customer-special-bill-data',
          name: 'CustomerSpecialBillDataList',
          component: () => import('../components/CustomerSpecialBillData/CustomerSpecialBillDataList.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/special-bill-data',
          name: 'SpecialBillDataList',
          component: () => import('../components/SpecialBillData/SpecialBillDataList.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/customer-meeting',
          name: 'CustomerMeetingList',
          component: () => import('../components/CustomerMeeting/CustomerMeetingList.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/customer/:id/create-customer-meeting',
          name: 'CustomerMeetingCreate',
          component: () => import('../components/CustomerMeeting/CreateCustomerMeeting.vue'),
          meta: {
            requiresAuth: true,
          },
          props: true,
        },
        {
          path: '/customer-meeting/:id/show',
          name: 'CustomerMeetingShow',
          component: () => import('../components/CustomerMeeting/CustomerMeetingShow/index.vue'),
          meta: {
            requiresAuth: true,
          },
          props: true,
        },
        {
          path: '/customer-meeting/:id/edit',
          name: 'EditCustomerMeeting',
          component: () => import('../components/CustomerMeeting/EditCustomerMeeting.vue'),
          meta: {
            requiresAuth: true,
          },
          props: true,
        },
        {
          path: '/customer-use-product',
          name: 'CustomerUseProduct',
          component: () => import('../components/CustomerUseProduct/CustomerUseProductList.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/customer-use-service',
          name: 'CustomerUseService',
          component: () => import('../components/CustomerUseService/CustomerUseServiceList.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/customer-inventory',
          name: 'CustomerInventory',
          component: () => import('../components/CustomerInventory/CustomerInventoryList.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/calendar',
          name: 'CalendarShow',
          component: () => import('../components/Calendar.vue'),
          meta: {
            requiresAuth: true,
          },
          props: true,
        },
        {
          path: '/profile',
          name: 'Profile',
          component: () => import('../components/User/Profile/index.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/link-facebook-profile',
          name: 'LinkFacebookProfile',
          component: () => import('../components/User/Profile/linkFacebookProfileCard.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/link-google-profile',
          name: 'LinkGoogleProfile',
          component: () => import('../components/User/Profile/linkGoogleProfileCard.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/link-line-profile',
          name: 'LinkLineProfile',
          component: () => import('../components/User/Profile/linkLineProfileCard.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/contact',
          name: 'contact',
          component: () => import('../components/Contact.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/forbidden',
          name: 'forbidden',
          component: () => import('../components/403_Forbidden.vue'),
          meta: {
            requiresAuth: true,
          },
        },

      ],
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('../components/Login.vue'),
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import('../components/Login.vue'),
    },
    {
      path: '/loginsuccess',
      name: 'LoginSuccess',
      component: () => import('../components/User/LoginSuccess.vue'),
    },
    {
      path: '/liff',
      name: 'Liff',
      component: () => import('../components/Liff/Liff.vue'),
    },
    {
      path: '*',
      redirect: '/',
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    // if (!store.getters.isLoggedIn) {
    if (!store.getters['user/isLoggedIn']) {
      next({ name: 'login' })
    } else {
      next() // go to wherever I'm going
    }
  } else {
    next() // does not require auth, make sure to always call next()!
  }
})

export default router
