import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user/'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const userState = createPersistedState({
  key: 'userState',
  paths: ['user'],
  storage: window.sessionStorage
})

export default new Vuex.Store({
  modules: {
    user,
  },
  plugins: [userState],
  strict: debug,
})
